import React from "react";
import { styled } from "baseui";
import { themedStyled } from "../../theme";

const Container = styled("div", () => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: "500px",
  width: "100%",
  padding: "5%",
  position: "relative",
}));

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const Dots = themedStyled("div", ({ $theme, delay }) => ({
  display: "inline-block",
  background: $theme.colors.primary,
  height: "5px",
  width: "5px",
  opacity: 0,
  borderRadius: "50%",
  transform: "translateX(-300px)",
  margin: "0 2px 0 2px",
  animationDuration: "4s",
  animationIterationCount: "infinite",
  animationName: {
    from: {
      transform: "translateX(0px)",
      opacity: "0.8",
    },
    to: {
      transform: "translateX(300px)",
      opacity: "0",
    },
  },
  animationDelay: delay,
}));

export interface InLineLoaderProps {
  color?: string;
}

const Dot = ({ delay }: { delay: string }) => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return <Dots delay={delay} />;
};

export const InLineLoader = React.memo((props: InLineLoaderProps) => {
  const { color = "#4092de" } = props;

  return (
    <Container color={color}>
      <Dot delay=".8s" />
      <Dot delay=".7s" />
      <Dot delay=".6s" />
      <Dot delay=".5s" />
      <Dot delay=".4s" />
      <Dot delay=".3s" />
      <Dot delay=".2s" />
      <Dot delay=".1s" />
    </Container>
  );
});

InLineLoader.displayName = "InLineLoader";
