import React, {
  createContext,
  PropsWithChildren,
  useContext,
  useReducer,
} from "react";
import { Order } from "../containers/OrderForm/OrderForm";

interface State {
  order: Order[];
}

const initialState: State = {
  order: [],
};

export interface ContextType extends State {
  addOrder(order: Order): void;
  clearOrder(): void;
  setOrder(order: Order[]): void;
}

enum actions {
  ADD_ORDER = "ADD_ORDER",
  CLEAR_ORDER = "CLEAR_ORDER",
  SET_ORDER = "SET_ORDER",
}

type Action =
  | { type: actions.ADD_ORDER; value: Order }
  | { type: actions.CLEAR_ORDER }
  | { type: actions.SET_ORDER; value: Order[] };

function reducer(state: State, action: Action) {
  switch (action.type) {
    case actions.ADD_ORDER:
      return { ...state, order: [...state.order, action.value] };
    case actions.CLEAR_ORDER:
      return { ...state, order: [] };
    case actions.SET_ORDER:
      return { ...state, order: action.value };
    default:
      return state;
  }
}

export const OrderContext = createContext<ContextType>({
  ...initialState,
  addOrder() {
    console.warn("no app provider");
  },
  clearOrder() {
    console.warn("no app provider");
  },
  setOrder() {
    console.warn("no app provider");
  },
});

export const useOrderContext = () => useContext(OrderContext);

export default function OrderProvider({
  children,
}: PropsWithChildren<unknown>) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const value = {
    ...state,
    addOrder(order: Order) {
      dispatch({ type: actions.ADD_ORDER, value: order });
    },
    clearOrder() {
      dispatch({ type: actions.CLEAR_ORDER });
    },
    setOrder(order: Order[]) {
      dispatch({ type: actions.SET_ORDER, value: order });
    },
  };
  return (
    <OrderContext.Provider value={value}>{children}</OrderContext.Provider>
  );
}
