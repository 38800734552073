// **************** ROUTE CONSTANT START **************************
// General Page Section
export const DASHBOARD = "/";
export const PRODUCTS = "/products";
export const BRAND = "/brand";
export const MODEL = "/model";
export const LOGIN = "/login";
export const LOGOUT = "/logout";
export const ORDERS = "/orders";
export const CUSTOMERS = "/customers";
export const IMPORT = "/import";
export const SETTINGS = "/settings";
export const STAFF_MEMBERS = "/staff-members";
export const SITE_SETTINGS = "/site-settings";
export const IMPORT_LIST = "/import-list";
export const IMPORT_DETAIL = "/import/:id";
export const ORDER_DETAIL = "/orders/:id";
export const ORDER_PRINT = "/orders/print/:id";
export const ORDER_FORM = "/orderform";
export const PAYMENTS = "/payments";
export const REPORTS = "/reports";
export const SALE_REPORT = "/reports/sale-report";
// **************** ROUTE CONSTANT END **************************

export const CURRENCY = "$";

export const ITEMS_PER_PAGE = 20;
