import React, { useState } from "react";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import jwtDecode from "jwt-decode";
import auth from "../api/auth";

interface AuthProps {
  isAuthenticated: boolean;
  authenticate: (loginData: {
    username: string;
    password: string;
  }) => Promise<void>;
  signOut: (callback?: () => void) => void;
}

export const AuthContext = React.createContext({} as AuthProps);

const isValidToken = () => {
  const token = localStorage.getItem("token");
  // JWT decode & check token validity & expiration.
  try {
    jwtDecode(token);
    return true;
  } catch {
    return false;
  }
};

interface Props {
  children: React.ReactNode;
}

const AuthProvider = (props: Props) => {
  const [isAuthenticated, makeAuthenticated] = useState(isValidToken());
  function authenticate({
    username,
    password,
  }: {
    username: string;
    password: string;
  }) {
    return auth
      .login(username, password)
      .then((res) => {
        makeAuthenticated(true);
        localStorage.setItem("token", res.data.jwt);
        localStorage.setItem("username", res.data.user.username);
        return Promise.resolve();
      })
      .catch((err) => console.log(err));
  }
  function signOut(cb?: () => void) {
    makeAuthenticated(false);
    localStorage.removeItem("token");
    localStorage.removeItem("username");
    setTimeout(() => {
      if (cb) {
        cb();
      }
    }, 100);
  }
  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        authenticate,
        signOut,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
