import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { Client as Styletron } from "styletron-engine-atomic";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { Provider as StyletronProvider } from "styletron-react";
import { BaseProvider } from "baseui";
import { theme } from "./theme";
import Routes from "./routes";
import * as serviceWorker from "./serviceWorker";
import "./theme/global.css";

function App() {
  const engine = new Styletron();
  return (
    <StyletronProvider value={engine}>
      <BaseProvider theme={theme}>
        <BrowserRouter>
          <Routes />
        </BrowserRouter>
      </BaseProvider>
    </StyletronProvider>
  );
}

ReactDOM.render(<App />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
