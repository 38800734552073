import { AxiosPromise } from "axios";
import axios from "./index";

export interface LoginResponse {
  jwt: string;
  user: {
    username: string;
  };
}

const login = (
  email: string,
  password: string
): AxiosPromise<LoginResponse> => {
  return axios.post(
    "auth/local",
    { identifier: email, password },
    { headers: { Authorization: "" } }
  );
};

export default {
  login,
};
