import React, { useContext, lazy, Suspense, PropsWithChildren } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import {
  LOGIN,
  PRODUCTS,
  BRAND,
  DASHBOARD,
  ORDERS,
  SETTINGS,
  CUSTOMERS,
  IMPORT,
  IMPORT_LIST,
  IMPORT_DETAIL,
  ORDER_FORM,
  ORDER_DETAIL,
  ORDER_PRINT,
  MODEL,
  PAYMENTS,
  REPORTS,
  SALE_REPORT,
} from "settings/constants";
import AuthProvider, { AuthContext } from "context/auth";
import OrderProvider from "context/order";
import { InLineLoader } from "components/InlineLoader/InlineLoader";
import { RouteProps } from "react-router";

const Products = lazy(() => import("containers/Products/Products"));
const AdminLayout = lazy(() => import("containers/Layout/Layout"));
const Dashboard = lazy(() => import("containers/Dashboard/Dashboard"));
const Brand = lazy(() => import("containers/Brand/Brand"));
const Orders = lazy(() => import("containers/Orders/Orders"));
const Settings = lazy(() => import("containers/Settings/Settings"));
const Customers = lazy(() => import("containers/Customers/Customers"));
const Import = lazy(() => import("containers/Import/Import"));
const Login = lazy(() => import("containers/Login/Login"));
const NotFound = lazy(() => import("containers/NotFound/NotFound"));
const ImportList = lazy(() => import("containers/ImportList/ImportList"));
const ImportDetail = lazy(() => import("containers/ImportDetail/ImportDetail"));
const OrderForm = lazy(() => import("containers/OrderForm/OrderForm"));
const OrderDetail = lazy(() => import("containers/OrderDetail/OrderDetail"));
const OrderPrint = lazy(() => import("containers/OrderPrint/OrderPrint"));
const Model = lazy(() => import("containers/Model/Model"));
const Payments = lazy(() => import("containers/Payments/Payments"));
const Reports = lazy(() => import("containers/Reports/Reports"));
const SaleReport = lazy(() => import("containers/Reports/SaleReport"));

/**
 *
 *  A wrapper for <Route> that redirects to the login
 * screen if you're not yet authenticated.
 *
 */

function PrivateRoute({ children, ...rest }: PropsWithChildren<RouteProps>) {
  const { isAuthenticated } = useContext(AuthContext);

  return (
    <Route
      {...rest}
      render={({ location }) =>
        isAuthenticated ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: location },
            }}
          />
        )
      }
    />
  );
}

function PrivateAdminRoute({
  children,
  ...rest
}: PropsWithChildren<RouteProps>) {
  return (
    <PrivateRoute {...rest}>
      <AdminLayout>
        <Suspense fallback={<InLineLoader />}>{children}</Suspense>
      </AdminLayout>
    </PrivateRoute>
  );
}

const Routes = () => {
  return (
    <AuthProvider>
      <OrderProvider>
        <Suspense fallback={<InLineLoader />}>
          <Switch>
            <PrivateAdminRoute exact={true} path={DASHBOARD}>
              <Dashboard />
            </PrivateAdminRoute>
            <PrivateAdminRoute path={PRODUCTS}>
              <Products />
            </PrivateAdminRoute>
            <PrivateAdminRoute path={BRAND}>
              <Brand />
            </PrivateAdminRoute>
            <PrivateAdminRoute path={MODEL}>
              <Model />
            </PrivateAdminRoute>
            <PrivateRoute path={ORDER_PRINT}>
              <Suspense fallback={<InLineLoader />}>
                <OrderPrint />
              </Suspense>
            </PrivateRoute>
            <PrivateAdminRoute path={ORDER_DETAIL}>
              <OrderDetail />
            </PrivateAdminRoute>
            <PrivateAdminRoute path={ORDERS}>
              <Orders />
            </PrivateAdminRoute>
            <PrivateAdminRoute path={CUSTOMERS}>
              <Customers />
            </PrivateAdminRoute>
            <PrivateAdminRoute path={IMPORT_DETAIL}>
              <ImportDetail />
            </PrivateAdminRoute>
            <PrivateAdminRoute path={IMPORT}>
              <Import />
            </PrivateAdminRoute>
            <PrivateAdminRoute path={SETTINGS}>
              <Settings />
            </PrivateAdminRoute>
            <PrivateAdminRoute path={IMPORT_LIST}>
              <ImportList />
            </PrivateAdminRoute>
            <PrivateAdminRoute path={ORDER_FORM}>
              <OrderForm />
            </PrivateAdminRoute>
            <PrivateAdminRoute path={PAYMENTS}>
              <Payments />
            </PrivateAdminRoute>
            <PrivateAdminRoute path={SALE_REPORT}>
              <SaleReport />
            </PrivateAdminRoute>
            <PrivateAdminRoute path={REPORTS}>
              <Reports />
            </PrivateAdminRoute>
            <Route path={LOGIN}>
              <Login />
            </Route>
            <Route component={NotFound} />
          </Switch>
        </Suspense>
      </OrderProvider>
    </AuthProvider>
  );
};

export default Routes;
